import _ from 'lodash';

import { ActionTypes, AlertTypes, COLORS, ErrorTypes } from './constants';
import {
  dispatchDataAsPayload,
  fulfillRequest,
  rejectRequest,
  startRequest
} from './dispatchUtils';
import * as ApiUtilsV5 from './utils/ApiUtilsV5';
import { getDrivers, getDriversOutliers } from './utils/ApiUtilsV5';
import { Version } from './utils/version';
import { getSentimentStatus, SENTIMENT_STATUS } from './utils/sentimentStatus';
import { camelCaseKeys } from './utils/common';
import { BooleanConcept, Concept } from './classes/Concepts';

const notify = (type, description) =>
  dispatchDataAsPayload(ActionTypes.NOTIFICATION, { description, type });

export const notifier = {
  error: _.partial(notify, AlertTypes.ERROR),
  info: _.partial(notify, AlertTypes.INFO),
  success: _.partial(notify, AlertTypes.SUCCESS),
  warning: _.partial(notify, AlertTypes.WARNING)
};

const requestsByActionType = {};

const abortRequests = actionType =>
  requestsByActionType[actionType] &&
  requestsByActionType[actionType].forEach(req => req.abort && req.abort());

const saveRequests = (actionType, ...requests) => {
  requestsByActionType[actionType] = [
    ...(requestsByActionType[actionType] || []),
    ...requests.filter(req => req != null)
  ];
};

export function getWorkspaces() {
  startRequest(ActionTypes.WORKSPACES_GET);
  return ApiUtilsV5.getWorkspaces()
    .then(response =>
      fulfillRequest(ActionTypes.WORKSPACES_GET, {
        workspaces: response.workspaces,
        defaultWorkspaceId: response.default_workspace
      })
    )
    .catch(error => {
      console.error(error);
      rejectRequest(ActionTypes.WORKSPACES_GET, { error });
    });
}

export function selectSidePanelTab(tabKey) {
  dispatchDataAsPayload(ActionTypes.SIDE_PANEL_SELECT_TAB, tabKey);
}

export function markDocumentsForDelete(deletedDocs) {
  dispatchDataAsPayload(ActionTypes.DOC_MARKED_FOR_DELETE, deletedDocs);
}

export function loadMoreDocuments(projectId, concept, filter=[],matchType='exact', isSentimentReady=false, limit = 50, offset=0) {
  startRequest(ActionTypes.DOC_SEARCH_RESULTS_LOAD_MORE);
  return ApiUtilsV5.getDocs(projectId, concept, filter, matchType, isSentimentReady, limit, offset)
    .then(moreDocs => {
      return moreDocs;
    });
}

export function selectConcept(projectId, concept, filter, isSentimentReady) {
  abortRequests(ActionTypes.CONCEPT_SELECT);

  const reqs = [
    concept
      ? undefined
      : ApiUtilsV5.getDocs(projectId, concept, filter, null, isSentimentReady),
    concept
      ? ApiUtilsV5.getDocs(
          projectId,
          concept,
          filter,
          'exact',
          isSentimentReady
        )
      : undefined,
    concept
      ? ApiUtilsV5.getDocs(
          projectId,
          concept,
          filter,
          'conceptual',
          isSentimentReady
        )
      : undefined,
    concept
      ? ApiUtilsV5.getSearchDetails(projectId, concept, filter)
      : undefined
  ];

  saveRequests(ActionTypes.CONCEPT_SELECT, ..._.compact(reqs));

  startRequest(ActionTypes.CONCEPT_SELECT, {
    projectId,
    concept
  });

  return Promise.all(reqs)
    .then(([docs, exactDocs, conceptualDocs, topConcepts]) => {
      fulfillRequest(ActionTypes.CONCEPT_SELECT, {
        projectId,
        concept: exactDocs?.search ?? concept,
        results: {
          docs: concept
            ? _.sortBy(
                [...exactDocs.matches, ...conceptualDocs.matches],
                'matchScore'
              ).reverse()
            : docs.matches,
          topConcepts,
          filterDocCount: concept ? exactDocs.filterDocCount + conceptualDocs.filterDocCount : docs.filterDocCount
        }
      });
    })
    .catch(error => {
      console.error(error);
      /* If the request was aborted, that means another one was started, so
       * don't reject the request.
       *
       * This fixes a bug where by rejecting the request, the selection is set
       * to null, which in turn triggers an addition call to `selectConcept`
       * (with the null selection) that would then abort the request that
       * aborted this one (thereby clearing the selection altogether). */
      if (error?.code === undefined && error?.message.includes('abort')) {
        return;
      }
      rejectRequest(ActionTypes.CONCEPT_SELECT, {
        projectId,
        concept,
        error
      });
    });
}


export function selectConceptOnlySearch(projectId, concept, filter, isSentimentReady) {
  abortRequests(ActionTypes.CONCEPT_SELECT);

  const reqs = [
    ApiUtilsV5.getDocs(projectId, concept, filter, null, isSentimentReady),
    ApiUtilsV5.getSearchDetails(projectId, concept, filter)
  ];

  saveRequests(ActionTypes.CONCEPT_SELECT, ...reqs);

  startRequest(ActionTypes.CONCEPT_SELECT, {
    projectId,
    concept
  });

  return Promise.all(reqs)
    .then(([docs, searchDetails]) => {
      fulfillRequest(ActionTypes.CONCEPT_SELECT, {
        projectId,
        concept,
        results: {
          docs: docs.matches,
          topConcepts: searchDetails.topConcepts,
          filterDocCount: docs.filterDocCount
        }
      });
    })
    .catch(error => {
      console.error(error);
      if (error?.code === undefined && error?.message.includes('abort')) {
        return; // Ignore abort errors as per the common pattern
      }
      rejectRequest(ActionTypes.CONCEPT_SELECT, {
        projectId,
        concept,
        error
      });
    });
}

export function loadProject(
  projectId,
  concept,
  filter = [],
  minScienceVersion
) {
  abortRequests(ActionTypes.PROJECT_LOAD);

  startRequest(ActionTypes.PROJECT_LOAD, { projectId, concept });

  // set the api call to update the last_access_time of the project
  const projectInfoPromise = ApiUtilsV5.getProject(projectId, null, true);

  saveRequests(ActionTypes.PROJECT_LOAD, projectInfoPromise);
  return projectInfoPromise
    .then(project => {
      if (project.last_build_info.start_time == null) {
        throw { code: ErrorTypes.PROJECT_EMPTY, project };
      } else if (!project.last_build_info.stop_time) {
        throw { code: ErrorTypes.PROJECT_NOT_READY, project };
      }

      const projectScienceVersion = project.last_build_info.science_version;
      if (
        minScienceVersion &&
        new Version(projectScienceVersion).isLessThan(minScienceVersion)
      ) {
        // Throw a non-standard error shape so that we can update the project
        // in the store. The catch below should standardize it back to the
        // expected error shape.
        throw { code: ErrorTypes.PROJECT_NEEDS_UPDATING, project };
      }

      const sentimentStatus = getSentimentStatus(project);
      const isSentimentReady = sentimentStatus === SENTIMENT_STATUS.READY;

      // Now that the project has been fetched and normalized, fetch the
      // top, active, and suggested concepts for it
      const filterInfo = ApiUtilsV5.getFilterInfo(projectId, filter);
      const metadata = ApiUtilsV5.getMetadata(projectId);
      const docSearchResults = concept
        ? undefined
        : ApiUtilsV5.getDocs(
            projectId,
            concept,
            filter,
            null,
            isSentimentReady
          );
      const exactDocs = concept
        ? ApiUtilsV5.getDocs(
            projectId,
            concept,
            filter,
            'exact',
            isSentimentReady
          )
        : undefined;
      const conceptualDocs = concept
        ? ApiUtilsV5.getDocs(
            projectId,
            concept,
            filter,
            'conceptual',
            isSentimentReady
          )
        : undefined;
      const conceptSearchResults = concept
        ? ApiUtilsV5.getSearchDetails(projectId, concept, filter)
        : undefined;
      const activeConcepts = ApiUtilsV5.getActiveConceptList(projectId);
      const sharedConceptLists = ApiUtilsV5.getSharedConceptLists(projectId);
      const sharedViews = ApiUtilsV5.getSharedViews(projectId);
      const dashboardProject = ApiUtilsV5.getDashboardProject(projectId);
      saveRequests(
        ActionTypes.PROJECT_LOAD,
        filterInfo,
        metadata,
        docSearchResults,
        exactDocs,
        conceptualDocs,
        conceptSearchResults,
        activeConcepts,
        sharedConceptLists,
        sharedViews,
        dashboardProject
      );

      return Promise.all([
        project,
        filterInfo,
        activeConcepts,
        ApiUtilsV5.getSuggestions(projectId),
        metadata,
        docSearchResults,
        exactDocs,
        conceptualDocs,
        conceptSearchResults,
        sharedConceptLists,
        sharedViews,
        dashboardProject
      ]);
    })
    .then(
      ([
        project,
        filterInfo,
        activeConceptsResults,
        suggestions,
        metadata,
        docSearchResults,
        exactDocs,
        conceptualDocs,
        conceptSearchResults,
        sharedConceptLists,
        sharedViews,
        dashboardProject
      ]) => {
        const searchedConcept = concept?.update(exactDocs.search) ?? null;

        const activeConcepts = activeConceptsResults.concepts;
        const activeConceptListName = activeConceptsResults.name;
        const topConcepts = filterInfo?.concepts;

        if (
          dashboardProject?.project_id &&
          dashboardProject?.task_has_run === true
        ) {
          project = {
            ...project,
            createdByDataStream: true
          };
        } else {
          project = {
            ...project,
            createdByDataStream: false
          };
        }

        fulfillRequest(ActionTypes.PROJECT_LOAD, {
          projectId,
          project,
          topConcepts,
          activeConceptListName,
          activeConcepts,
          suggestions,
          metadata,
          docSearchResults: concept
            ? _.sortBy(
                [...exactDocs.matches, ...conceptualDocs.matches],
                'matchScore'
              ).reverse()
            : docSearchResults.matches,
          filterDocCount: concept ? exactDocs.filterDocCount + conceptualDocs.filterDocCount : docSearchResults.filterDocCount,
          conceptSearchResults,
          concept: searchedConcept,
          filterCount: filterInfo.filterCount,
          sharedConceptLists,
          sharedViews
        });
      }
    )
    .catch(({ project, ...error }) => {
      // We might throw an object with a non-standard error shape, in which case
      // we standardize it here. Specifically, we might pass along a project in
      // the error object.
      console.error(error);
      rejectRequest(ActionTypes.PROJECT_LOAD, { projectId, error, project });
    });
}

export function loadSharedConceptLists(projectId) {
  startRequest(ActionTypes.SHARED_CONCEPT_LISTS_LOAD, { projectId });

  ApiUtilsV5.getSharedConceptLists(projectId)
    .then(sharedConceptLists =>
      fulfillRequest(ActionTypes.SHARED_CONCEPT_LISTS_LOAD, {
        projectId,
        sharedConceptLists
      })
    )
    .catch(error => {
      console.error(error);
      rejectRequest(ActionTypes.SHARED_CONCEPT_LISTS_LOAD, {
        projectId,
        error
      });
    });
}

/**
 * Adds concepts being visualized to the user's active concept list. Concepts
 * are added to the top of active concept list and assigned a color. If one of
 * the concepts being visualized was already an active concept, it will keep its
 * existing color, but be moved to the top along the other concepts being
 * visualized.
 *
 *
 * @param {string} projectId
 * @param {Concept[]} concepts - The concepts being visualized
 * @param {Concept[]} activeConcepts - Current active concept list
 * @param {Concept[]} suggestions - The suggested concepts from the store
 * @returns {Promise}
 */
export function addFromVisualization(
  projectId,
  concepts,
  activeConcepts,
  suggestions
) {
  startRequest(ActionTypes.ACTIVE_CONCEPTS_REPLACE, {});

  return saveUnsavedConcepts()
    .then(moveAddedConceptsToTopOfList)
    .then(activeConcepts => {
      fulfillRequest(ActionTypes.ACTIVE_CONCEPTS_REPLACE, { activeConcepts });
    })
    .catch(() => {
      rejectRequest(ActionTypes.ACTIVE_CONCEPTS_REPLACE, {});
    });

  function makeConceptMap(concepts) {
    return Object.fromEntries(concepts.map(c => [c.toString(), c]));
  }

  /** Removes concepts in `conceptsToSubtract` from `concepts`. Equality is
   * determined by two concepts having the same texts. */
  function subtract(concepts, conceptsToSubtract) {
    const conceptsToSubtractMap = makeConceptMap(conceptsToSubtract);
    return concepts.filter(c => !conceptsToSubtractMap[c.toString()]);
  }

  function saveUnsavedConcepts() {
    const unsavedConcepts = subtract(concepts, activeConcepts);

    // Assign colors to the unsaved concepts. First, use up any unused colors,
    // then randomly assign colors
    const usedColors = _.uniq(activeConcepts.map(ac => ac.color));
    const unusedColors = _.difference(COLORS, usedColors);
    const suggestionsMap = makeConceptMap(suggestions);

    const conceptsWithColor = unsavedConcepts.map(concept => {
      const matchingSuggestion = suggestionsMap[concept.toString()];
      if (matchingSuggestion) {
        return concept.update({ color: matchingSuggestion.color });
      }
      return concept.update({
        color: unusedColors.shift() ?? _.sample(COLORS)
      });
    });
    return ApiUtilsV5.addActiveConcepts(projectId, ...conceptsWithColor);
  }

  function moveAddedConceptsToTopOfList(newlySavedConcepts) {
    const unchangedActiveConcepts = subtract(activeConcepts, concepts);
    const activeConceptsMap = makeConceptMap(activeConcepts);
    const newlySavedConceptsMap = makeConceptMap(newlySavedConcepts);

    const getMatchingSavedConcept = concept =>
      newlySavedConceptsMap[concept.toString()] ??
      activeConceptsMap[concept.toString()];

    const orderedConcepts = concepts
      .map(getMatchingSavedConcept)
      .concat(unchangedActiveConcepts);

    return ApiUtilsV5.reorderActiveConcepts(
      projectId,
      orderedConcepts.map(c => c.sharedConceptId)
    ).then(() => orderedConcepts);
  }
}

export function addActiveConcepts(
  conceptSelector,
  filter,
  projectId,
  ...concepts
) {
  const transformedConcepts = concepts.map(concept => {
    const hasAndSeparator = concept.texts.some(text => text.includes('&'));
    if (hasAndSeparator) {
      const updatedTexts = concept.texts.flatMap(text =>
        text.includes('&') ? text.split('&').map(t => t.trim()) : text
      );
      return new BooleanConcept({
        ...concept,
        texts: updatedTexts,
        type: "and"
      });
    }

    return concept;
  });

  return ApiUtilsV5.addActiveConcepts(projectId, ...transformedConcepts)
    .then(activeConcepts => {
      dispatchDataAsPayload(ActionTypes.ACTIVE_CONCEPTS_ADD, {
        projectId,
        activeConcepts
      });
      return activeConcepts;
    })
    .catch(error => {
      console.error(error);
    });
}

export function reorderActiveConcepts(projectId, sharedConceptIds) {
  startRequest(ActionTypes.ACTIVE_CONCEPTS_REORDER, {
    projectId,
    sharedConceptIds
  });

  return ApiUtilsV5.reorderActiveConcepts(projectId, sharedConceptIds)
    .then(() =>
      fulfillRequest(ActionTypes.ACTIVE_CONCEPTS_REORDER, {
        projectId,
        sharedConceptIds
      })
    )
    .catch(error => {
      console.error(error);
      rejectRequest(ActionTypes.ACTIVE_CONCEPTS_REORDER, { projectId, error });
    });
}

export function openConceptList(projectId, conceptListId) {
  startRequest(ActionTypes.CONCEPT_LIST_OPEN);

  return ApiUtilsV5.openSharedConceptList(projectId, conceptListId)
    .then(() => ApiUtilsV5.getActiveConceptList(projectId))
    .then(activeConcepts =>
      fulfillRequest(ActionTypes.CONCEPT_LIST_OPEN, {
        activeConcepts: activeConcepts.concepts,
        activeConceptListName: activeConcepts.name
      })
    )
    .catch(error => {
      rejectRequest(ActionTypes.CONCEPT_LIST_OPEN);
      throw error;
    });
}

export function makeEmptyConceptList(projectId, name) {
  startRequest(ActionTypes.SHARED_CONCEPT_LIST_ADD, {});

  return ApiUtilsV5.restoreSharedConceptList(projectId, name, [])
    .then(({ concept_list_id }) =>
      ApiUtilsV5.openSharedConceptList(projectId, concept_list_id).then(() => {
        const conceptList = { concept_list_id, name, concepts: [] };
        fulfillRequest(ActionTypes.SHARED_CONCEPT_LIST_ADD, { conceptList });
        return concept_list_id;
      })
    )
    .catch(() => {
      rejectRequest(ActionTypes.SHARED_CONCEPT_LIST_ADD, {});
    });
}

export function makeConceptList(projectId, name, concepts) {
  startRequest(ActionTypes.SHARED_CONCEPT_LIST_ADD, {});

  return ApiUtilsV5.restoreSharedConceptList(projectId, name, concepts)
    .catch(() => {
      rejectRequest(ActionTypes.SHARED_CONCEPT_LIST_ADD, {});
    });
}

export function undoCreatingConceptList(
  projectId,
  previousActiveConceptList,
  createdListId
) {
  startRequest(ActionTypes.SHARED_CONCEPT_LIST_ADD_UNDO, {});
  const { name, concepts } = previousActiveConceptList;
  return ApiUtilsV5.deleteSharedConceptListById(projectId, createdListId)
    .then(() => ApiUtilsV5.restoreActiveConceptList(projectId, name, concepts))
    .then(() => {
      fulfillRequest(ActionTypes.SHARED_CONCEPT_LIST_ADD_UNDO, {
        activeConcepts: concepts,
        activeConceptListName: name,
        createdListId
      });
    })
    .catch(() => {
      rejectRequest(ActionTypes.SHARED_CONCEPT_LIST_ADD_UNDO, {});
    });
}

export function shareActiveConceptList(projectId, listName) {
  // Use the create endpoint with the "overwrite" flag set to true to update a
  // pre-existing shared list
  return ApiUtilsV5.createConceptList(projectId, listName, true)
    .then(savedList => {
      fulfillRequest(ActionTypes.SHARED_CONCEPT_LIST_SAVE_CONCEPTS, {
        savedList
      });
      return savedList;
    })
    .catch(error => console.error(error));
}

export function undoUpdateConceptList(projectId, listName, oldConcepts) {
  return ApiUtilsV5.restoreSharedConceptList(
    projectId,
    listName,
    oldConcepts,
    true
  )
    .then(() => {
      fulfillRequest(ActionTypes.SHARED_CONCEPT_LIST_SAVE_CONCEPTS_UNDO, {
        listName,
        oldConcepts
      });
    })
    .catch(error => console.error(error));
}

export function restoreActiveConceptList(projectId, name, concepts) {
  startRequest(ActionTypes.ACTIVE_LIST_RESTORE);

  return ApiUtilsV5.restoreActiveConceptList(projectId, name, concepts)
    .then(activeConcepts => {
      fulfillRequest(ActionTypes.ACTIVE_LIST_RESTORE, {
        activeConcepts: activeConcepts.concepts,
        activeConceptListName: activeConcepts.name
      });
    })
    .catch(error => {
      rejectRequest(ActionTypes.ACTIVE_LIST_RESTORE);
      throw error;
    });
}

export function updateActiveListName(activeConceptListName) {
  dispatchDataAsPayload(
    ActionTypes.ACTIVE_LIST_NAME_UPDATE,
    activeConceptListName
  );
}

export function updateSelectedView(selectedView) {
  dispatchDataAsPayload(ActionTypes.SELECTED_VIEW_UPDATE, selectedView);
}

export function selectFilter(projectId, filter, selection, isSentimentReady) {
  startRequest(ActionTypes.FILTER_SELECT, { projectId });
  return Promise.all([
    /* Fulfill the request without waiting for `selectConcept` to finish. This
     * allows us to update the filter info even if the call to `selectConcept`
     * is aborted.
     *
     * This fixes a bug where the filter would remain loading forever if a
     * separate `selectConcept` action was called while this action was still in
     * progress. */
    ApiUtilsV5.getFilterInfo(projectId, filter)
      .then(filterInfo => {
        fulfillRequest(ActionTypes.FILTER_SELECT, {
          projectId,
          filterCount: filterInfo.filterCount,
          topConcepts: filterInfo.concepts
        });
      })
      .catch(error => {
        console.error(error);
        rejectRequest(ActionTypes.FILTER_SELECT, { projectId, error });
      }),
    selectConcept(projectId, selection, filter, isSentimentReady)
  ]);
}

export function showFilterInfoSpinner() {
  dispatchDataAsPayload(ActionTypes.FILTER_INFO_SPINNER_SHOW);
}

export function hideFilterInfoSpinner() {
  dispatchDataAsPayload(ActionTypes.FILTER_INFO_SPINNER_HIDE);
}

export function removeActiveConcepts(
  conceptSelector,
  filter,
  projectId,
  ...sharedConceptIds
) {
  startRequest(ActionTypes.ACTIVE_CONCEPTS_REMOVE, {
    projectId,
    sharedConceptIds
  });

  return ApiUtilsV5.removeActiveConcepts(projectId, ...sharedConceptIds)
    .then(() => {
      fulfillRequest(ActionTypes.ACTIVE_CONCEPTS_REMOVE, {
        projectId,
        sharedConceptIds
      });
    })
    .catch(error => {
      console.error(error);
      rejectRequest(ActionTypes.ACTIVE_CONCEPTS_REMOVE, {
        projectId,
        sharedConceptIds,
        error
      });
    });
}

export function recolorActiveConcepts(projectId, concepts) {
  return ApiUtilsV5.updateActiveConcepts(projectId, concepts, ['color'])
    .then(concepts => {
      const payload = { concepts };
      dispatchDataAsPayload(ActionTypes.ACTIVE_CONCEPT_BULK_RECOLOR, payload);
    })
    .catch(error => console.error(error));
}

export function updateActiveConcept(
  projectId,
  updatedConcept,
  filter,
  isSelected,
  isSentimentReady = false
) {
  startRequest(ActionTypes.ACTIVE_CONCEPT_UPDATE, {
    projectId,
    updatedConcept
  });
  return ApiUtilsV5.updateActiveConcepts(projectId, [updatedConcept])
    .then(concepts => concepts[0])
    .then(updatedConceptFromAPI => {
      fulfillRequest(ActionTypes.ACTIVE_CONCEPT_UPDATE, {
        projectId,
        updatedConcept: updatedConceptFromAPI
      });

      if (isSelected) {
        selectConcept(
          projectId,
          updatedConceptFromAPI,
          filter,
          isSentimentReady
        );
      }
    })
    .catch(error => {
      console.error(error);
      rejectRequest(ActionTypes.ACTIVE_CONCEPT_UPDATE, {
        projectId,
        updatedConcept,
        error
      });
    });
}

export function editProject(projectId, name, description) {
  dispatchDataAsPayload(ActionTypes.PROJECT_EDIT, { name, description });
}

export function editNextBuildLanguage(nextBuildLanguage) {
  dispatchDataAsPayload(ActionTypes.PROJECT_EDIT_NEXT_BUILD_LANGUAGE, {
    nextBuildLanguage
  });
}

export function editProjectCollocation(collocation) {
  dispatchDataAsPayload(ActionTypes.PROJECT_EDIT_COLLOCATION, {
    collocation
  });
}

export function getSharedViews(projectId) {
  return ApiUtilsV5.getSharedViews(projectId).then(sharedViews => {
    dispatchDataAsPayload(ActionTypes.SHARED_VIEWS_GET, { sharedViews });
    return sharedViews;
  });
}

export function saveSharedView(projectId, sharedView) {
  return ApiUtilsV5.saveSharedView(projectId, sharedView).then(
    shared_view_id => {
      dispatchDataAsPayload(ActionTypes.SHARED_VIEW_SAVE, {
        sharedView: { ...sharedView, shared_view_id }
      });
    }
  );
}

export function deleteSharedView(projectId, sharedViewId) {
  return ApiUtilsV5.deleteSharedView(projectId, sharedViewId).then(() => {
    dispatchDataAsPayload(ActionTypes.SHARED_VIEW_DELETE, { sharedViewId });
  });
}

export function getConceptCountsOutliers(
  projectId,
  conceptSelector,
  filter = [],
  breakdowns = [],
  matchtype = 'both'
) {
  startRequest(ActionTypes.UPDATE_FILTER_COUNT, { projectId });

  return ApiUtilsV5.setOutlierFilter(projectId, matchtype)
    .then(() => {
      return ApiUtilsV5.getConceptCountsOutliers(
        projectId,
        conceptSelector,
        filter,
        breakdowns
      );
    })
    .then(response => {
      if (!response) {
        throw new Error('No response from getConceptCountsOutliers');
      }
      fulfillRequest(ActionTypes.UPDATE_FILTER_COUNT, {
        outlierFilterCount: response.filterCount
      });
      return response;
    })
    .catch(error => {
      console.error('Error processing concept counts outliers:', error);
      throw error;
    });
}

export function getSentimentsOutliers(
  projectId,
  conceptSelector,
  filter = [],
  matchType = 'both'
) {
  startRequest(ActionTypes.UPDATE_FILTER_COUNT, { projectId });

  return ApiUtilsV5.setOutlierFilter(projectId, matchType)
    .then(() => {
      return ApiUtilsV5.getSentimentOutliers(
        projectId,
        conceptSelector,
        filter
      );
    })
    .then(response => {
      if (!response) {
        throw new Error('No response from getConceptCountsOutliers');
      }
      fulfillRequest(ActionTypes.UPDATE_FILTER_COUNT, {
        outlierFilterCount: response.filterCount
      });
      return response;
    })
    .catch(error => {
      console.error('Error processing concept counts outliers:', error);
      throw error;
    });
}

export function fetchAndCombineSentimentData(
  projectId,
  conceptSelector,
  filter = [],
  matchType = 'both'
) {
  return Promise.all([
    ApiUtilsV5.getSentiment(projectId, conceptSelector, filter),
    getSentimentsOutliers(projectId, conceptSelector, filter, matchType)
  ])
    .then(([sentimentResponse, outlierResponse]) => {
      const camelCasedSentiment = camelCaseKeys(sentimentResponse);
      const camelCasedOutlier = camelCaseKeys(outlierResponse);
      const combinedMatches = [
        ...sentimentResponse.matches,
        ...outlierResponse.matches
      ];
      return {
        ...camelCasedSentiment,
        ...camelCasedOutlier,
        matches: combinedMatches
      };
    })
    .catch(error => {
      console.error('Error fetching or combining sentiment data:', error);
      throw error;
    });
}

export function getDriversCountsOutliers(
  projectId,
  filter,
  scoreField,
  conceptSelector,
  matchType
) {
  const matchTypeForOutliers = matchType === 'total' ? 'both' : 'exact';
  return getConceptCountsOutliers(
    projectId,
    conceptSelector,
    filter,
    [],
    matchTypeForOutliers
  )
    .then(() => {
      return getDriversOutliers(
        projectId,
        filter,
        scoreField,
        conceptSelector,
        matchType
      );
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

export function fetchAndCombineConceptCounts(
  projectId,
  conceptSelector,
  filter = [],
  breakdowns = [],
  matchType = 'both'
) {
  return Promise.all([
    ApiUtilsV5.getConceptCounts(projectId, conceptSelector, filter, breakdowns),
    getConceptCountsOutliers(
      projectId,
      conceptSelector,
      filter,
      breakdowns,
      matchType
    )
  ])
    .then(([normalResponse, outlierResponse]) => {
      const normalMatchCountsWithPrevalence = addPrevalenceToMatchCounts(normalResponse.matchCounts);
      const outlierMatchCountsWithPrevalence = addPrevalenceToMatchCounts(outlierResponse.matchCounts);
      return {
        status: 'success',
        matchCounts: combineArrays(
          normalMatchCountsWithPrevalence,
          outlierMatchCountsWithPrevalence
        ),
        filterCount: normalResponse.filterCount,
        totalCount: normalResponse.totalCount,
        breakdowns: combineBreakdowns(
          normalResponse.breakdowns,
          outlierResponse.breakdowns
        )
      };
    })
    .catch(error => {
      console.error('Error fetching or combining concept counts:', error);
      throw error;
    });
}

function combineArrays(array1, array2) {
  return [...array1, ...array2];
}

function combineBreakdowns(breakdowns1, breakdowns2) {
  const combined = [...breakdowns1];

  breakdowns2.forEach(b2 => {
    const existing = combined.find(
      b1 => b1.breakdown.name === b2.breakdown.name
    );
    if (existing) {
      existing.buckets = mergeBuckets(existing.buckets, b2.buckets);
    } else {
      combined.push(b2);
    }
  });

  return combined;
}

function addPrevalenceToMatchCounts(matchCounts) {
  return matchCounts
    .sort((a, b) => b.relevance - a.relevance)
    .map((concept, index) => ({
      ...concept,
      prevalence: index + 1
    }))
    .map(Concept.fromJSON);
}


function mergeBuckets(buckets1, buckets2) {
  const merged = [...buckets1];

  buckets2.forEach(bucket2 => {
    const existingBucket = merged.find(
      bucket1 => bucket1.label === bucket2.label
    );
    if (existingBucket) {
      existingBucket.match_counts = [
        ...existingBucket.match_counts,
        ...bucket2.match_counts
      ];
    } else {
      merged.push(bucket2);
    }
  });

  return merged;
}

export function fetchAndCombineDriversOutliers(
  projectId,
  filter,
  scoreField,
  conceptSelector,
  matchType
) {
  return Promise.all([
    getDrivers(projectId, filter, scoreField, conceptSelector, matchType),
    getDriversCountsOutliers(
      projectId,
      filter,
      scoreField,
      conceptSelector,
      matchType
    )
  ]).then(([drivers, outliers]) => {
    return [...drivers, ...outliers];
  });
}

// export function loadDocuments(projectId, concept, filter,matchType, isSentimentReady, limit = 50, offset) {
//   startRequest(ActionTypes.LOAD_DOCS);
//   return ApiUtilsV5.getDocs(projectId, concept, filter, matchType, isSentimentReady, limit, offset)
//     .then(docs => {
//       fulfillRequest(ActionTypes.LOAD_DOCS, {
//         docs: docs
//       });
//     });
// }


/**
 * Fetch drivers and sentiment data for a given project and concept selector.
 *
 * @param {string} projectId - The project's ID
 * @param {Object} conceptSelector - The concept selector for which to fetch data
 * @param {Array} filter - The filter to apply
 * @param {string} scoreField - The field used to score drivers
 * @param {string} matchType - The type of match (e.g., 'exact', 'conceptual')
 * @param {boolean} isSentimentReady - Flag to indicate if sentiment is ready to be fetched
 * @param {string} driversField - The field to fetch drivers for
 * @return {Promise} - Resolves with drivers and sentiment data
 */
export function fetchDriversAndSentiments(projectId, conceptSelector, filter = [], scoreField, matchType = 'both', isSentimentReady = false, driversField = null) {
  startRequest(ActionTypes.FETCH_DRIVERS_AND_SENTIMENTS, { projectId });

  const promises = [];

  if (isSentimentReady) {
    promises.push(ApiUtilsV5.getSentiment(projectId, conceptSelector, filter));
  }

  if (driversField) {
    promises.push(ApiUtilsV5.getDrivers(projectId, filter, driversField, conceptSelector, matchType));
  }

  return Promise.all(promises)
    .then((results) => {
      const combinedData = {};
      if (isSentimentReady) {
        combinedData.sentiment = results[0];
      }
      if (driversField) {
        combinedData.drivers = isSentimentReady ? results[1] : results[0];
      }
      fulfillRequest(ActionTypes.FETCH_DRIVERS_AND_SENTIMENTS, {
        projectId,
        combinedData,
      });
    })
    .catch((error) => {
      console.error('Error fetching drivers and sentiments:', error);
      rejectRequest(ActionTypes.FETCH_DRIVERS_AND_SENTIMENTS, {
        projectId,
        error,
      });
    });
}
