import { getConceptsToVisualizeParams } from './getConceptsToVisualizeParams';

export function sentimentSearchDefaults(params, store) {
  const conceptsToVisualizeSubQuery = getConceptsToVisualizeParams(
    params,
    store,
    { concepts: 'sentiment' }
  );

  const sortby = [
    'name-asc',
    'name-desc',
    'matches-desc',
    'matches-asc',
    'prevalence-desc',
    'prevalence-asc',
    'positive-asc',
    'positive-desc',
    'negative-asc',
    'negative-desc'
  ].includes(params.sortby)
    ? params.sortby
    : 'matches-desc';

  return { ...params, ...conceptsToVisualizeSubQuery, sortby };
}
