import _ from 'lodash';

import { DateField } from '../../classes/MetadataFields';
import {
  getDefaultDriversField,
  instanceOf,
  isValidDriversField
} from '../../drivers/utils';
import { getConceptsToVisualizeParams } from './getConceptsToVisualizeParams';

export function driversSearchDefaults(params, store) {
  const field = getField(params, store.metadata);
  const conceptsToVisualizeParams = getConceptsToVisualizeParams(
    params,
    store,
    { concepts: 'drivers', drivers_of: field }
  );
  const comparison_field = getComparisonField(params, store.metadata);

  const matchType = ['exact', 'total'].includes(params.match_type)
    ? params.match_type
    : 'total';

  const outlier_mode = ['only', 'include'].includes(params.outlier_mode)
    ? params.outlier_mode
    : null;

  const time = comparison_field
    ? ['previous_period', 'year_over_year'].includes(params.time)
      ? params.time
      : 'previous_period'
    : undefined;

  const display_same = comparison_field
    ? ['true', 'false'].includes(params.display_same)
      ? params.display_same
      : 'false'
    : undefined;

  const tab = [
    'visualization',
    'concepts',
    ...(comparison_field ? ['compared-concepts'] : [])
  ].includes(params.tab)
    ? params.tab
    : 'visualization';

  const sortby = [
    'name-asc',
    'name-desc',
    'matchCount-asc',
    'matchCount-desc',
    'prevalence-desc',
    'prevalence-asc',
    'averageScore-asc',
    'averageScore-desc',
    'absoluteDifference-asc',
    'absoluteDifference-desc'
  ].includes(params.sortby)
    ? params.sortby
    : 'absoluteDifference-desc';

  const sync_fields = ['yes', 'no'].includes(params.sync_fields)
    ? params.sync_fields
    : 'yes';

  return {
    ...params,
    ...conceptsToVisualizeParams,
    field,
    time,
    comparison_field,
    tab,
    sortby,
    display_same,
    sync_fields,
    match_type: matchType,
    outlier_mode
  };
}

const getComparisonField = (params, metadata) => {
  const predicate = { name: params.comparison_field };

  // Clear out drivers-over-time params if the user removes the date field from
  // the filter (via the filter sidebar panel)
  if (!_.find(params.filter, predicate)) {
    return undefined;
  }

  const dateFields = metadata.filter(instanceOf(DateField));
  if (!_.find(dateFields, predicate)) {
    return undefined;
  }

  return params.comparison_field;
};

const getField = (params, metadata) => {
  if (isValidDriversField(metadata, params.field)) {
    return params.field;
  } else if (isValidDriversField(metadata, params.drivers_of)) {
    return params.drivers_of;
  }

  return getDefaultDriversField(metadata);
};
